@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
:root {
  font-family: "Lato", sans-serif;
  font-size: 14px;

  --green: #47ab3d;
  --red: #dc2525;
  --yellow: #dfb50a;
  --purple: #544cbb;
  --black: #111;
  --lighter-black: #181818;
  --darkest-gray: #1d1d1d;
  --darker-gray: #3c3c3c;
  --dark-gray: #646161;
  --gray: #817f7f;
  --light-gray: #e0e0e0;
  --lighter-gray: #d7d7d7;
  --lightest-gray: #f6f6f6;
  --white: #fff;

  --spacing-xs: 5px;
  --spacing-s: 10px;
  --spacing-m: 15px;
  --spacing-l: 20px;
  --spacing-xl: 25px;
  --spacing-xxl: 30px;

  --border-radius-s: 5px;
  --app-width: 500px;
}

:root {
  --color-background: var(--lightest-gray);
  --color-header-background: var(--white);
  --color-text: var(--black);
  --color-subtext: var(--dark-gray);
  --color-border: var(--light-gray);
  --color-valid: var(--green);
  --color-invalid: var(--red);
  --color-warning: var(--yellow);
  --color-primary: var(--purple);
  --color-input-background: var(--white);
  --color-input-placeholder: var(--gray);
  --invert: 0%;
}

.dark-mode {
  --color-background: var(--lighter-black);
  --color-header-background: var(--black);
  --color-text: var(--white);
  --color-subtext: var(--lighter-gray);
  --color-border: var(--darker-gray);
  --color-input-background: var(--darkest-gray);
  --color-input-placeholder: var(--gray);
  --invert: 100%;
}

body {
  background-color: var(--color-background);
}
